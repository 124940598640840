import CustomErrorMessage from "../../shared/util/customErrorMessage";
import { ApiCall } from "../apiBase";

class DatafileResultService {
  async getAllDataPoints(datafileId, algorithmType) {
    let datapoints = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getAllDataPoints($datafileId: ID!, $algorithmType: String){
            getAllDataPoints(datafileId: $datafileId, algorithmType: $algorithmType) {
              dataPointIdentifier
              questionTitle
              dataPointType
              languagesToCheck
              keywords
              algorithms {
                IS
                PS
                SDS
                TS
                IBS
                CS
              }
            }
          }
        `,
        variables: {
          datafileId,
          algorithmType
        }
      }
    });

    if (result?.data?.data?.getAllDataPoints && !result?.data?.errors) {
      datapoints = result.data.data.getAllDataPoints;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
    return datapoints;
  }

  async getDatafile(datafileId) {
    let datafile = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDatafile($datafileId: ID!){
            getDatafile(datafileId: $datafileId) {
              title
              description
              numberOfRespondents
              numberOfQuestions
              numberOfNonProjectiveQuestions
              numberOfFilterQuestions
              dataCollectingMethods {
                CAWI
                CATI
                CAPI
                PAPI
              }
              amountOfCredits
              createdDate
              contributors
              owner {
                name {
                  first
                  last
                }
              },
              company {
                id
                name
              }
              isDeleted
              isCleaned
              cleaningMethod {
                SCORE_THRESHOLD
                WEIGHTING
              }
              scoreThreshold,
              isLiveCleaning
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (result?.data?.data?.getDatafile && !result?.data?.errors) {
      datafile = result.data.data.getDatafile;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }

    return datafile;
  }

  async getPredictionTypes(datafileId) {
    let datafile = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getPredictionTypes($datafileId: ID!){
            getPredictionTypes(datafileId: $datafileId) {
              DETAILED
              AVERAGE
              AGREEMENT
              NONPROJECTIVE
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (result?.data?.data?.getPredictionTypes && !result?.data?.errors) {
      datafile = result.data.data.getPredictionTypes;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }

    return datafile;
  }

  async getSDSPerAnswerOption(datafileId, dataPointIdentifier) {
    return new Promise((resolve, reject) => {
      ApiCall({
        method: "post",
        data: {
          query: `
            query getSDSPerAnswerOption($datafileId: ID!, $dataPointIdentifier: String!){
              getSDSPerAnswerOption(datafileId: $datafileId, dataPointIdentifier: $dataPointIdentifier) {
                name
                score
              }
            }
          `,
          variables: {
            datafileId: datafileId,
            dataPointIdentifier: dataPointIdentifier
          }
        }
      })
        .then((resp) => {
          if (resp?.data?.errors) {
            reject(resp);
          } else {
            resolve(resp.data.data.getSDSPerAnswerOption);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async getSDSRate(datafileId, dataPointIdentifier, cleaned) {
    return new Promise((resolve, reject) => {
      ApiCall({
        method: "post",
        data: {
          query: `
            query getSDSRate($datafileId: ID!, $dataPointIdentifier: String, $cleaned: Boolean){
              getSDSRate(datafileId: $datafileId, dataPointIdentifier: $dataPointIdentifier, cleaned: $cleaned) {
                  Positive
                  Negative
              }
            }
          `,
          variables: {
            datafileId: datafileId,
            dataPointIdentifier: dataPointIdentifier,
            cleaned
          }
        }
      })
        .then((resp) => {
          if (resp?.data?.errors) {
            reject(resp);
          } else {
            resolve(resp.data.data.getSDSRate);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async getQualityScore(datafileId, cleaned) {
    return new Promise((resolve, reject) => {
      ApiCall({
        method: "post",
        data: {
          query: `
            query getQualityScoreStats($datafileId: ID!, $cleaned: Boolean){
              getQualityScoreStats(datafileId: $datafileId, cleaned: $cleaned) {
                Mean
                StandardDeviation
                Median
              }
            }
          `,
          variables: {
            datafileId: datafileId,
            cleaned
          }
        }
      })
        .then((resp) => {
          if (resp?.data?.errors) {
            reject(resp);
          } else {
            resolve(resp.data.data.getQualityScoreStats);
          }
        })
        .catch((e) => {
          reject(e);
          // reject(result?.data?.errors[0]?.extensions?.code);
        });
      // if (result?.data?.data?.getQualityScoreStats && !result?.data?.errors) {
      //   resolve(result?.data?.data?.getQualityScoreStats);
      // } else {
      // throw Error(result?.data?.errors[0]?.extensions?.code);
      //}
    });
  }

  async getAverageScoreDataOld(
    datafileId,
    scoreType,
    dataPointIdentifier,
    cleaned,
    groupSelector
  ) {
    return new Promise((resolve, reject) => {
      ApiCall({
        method: "post",
        data: {
          query: `
          query getAverageScore($datafileId: ID!, $dataPointIdentifier: String, $scoreType: String!, $cleaned: Boolean, $groupSelector: String){
            getAverageScore(datafileId: $datafileId, dataPointIdentifier: $dataPointIdentifier, scoreType: $scoreType, cleaned: $cleaned, groupSelector: $groupSelector)
            }
          `,
          variables: {
            datafileId,
            dataPointIdentifier,
            scoreType,
            cleaned,
            groupSelector
          }
        }
      })
        .then((resp) => {
          if (resp?.data?.errors) {
            reject(resp);
          } else {
            resolve(resp.data.data.getAverageScore);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async getDistributionDataOld(
    datafileId,
    scoreType,
    dataPointIdentifier,
    cleaned,
    groupSelector
  ) {
    return new Promise((resolve, reject) => {
      ApiCall({
        method: "post",
        data: {
          query: `
            query getScoreDistributionValues($datafileId: ID!, $dataPointIdentifier: String, $scoreType: String, $cleaned: Boolean, $groupSelector: String){
              getScoreDistributionValues(datafileId: $datafileId, dataPointIdentifier: $dataPointIdentifier, scoreType: $scoreType, cleaned: $cleaned, groupSelector: $groupSelector)

            }
          `,
          variables: {
            datafileId,
            dataPointIdentifier,
            scoreType,
            cleaned,
            groupSelector
          }
        }
      })
        .then((resp) => {
          if (resp?.data?.errors) {
            reject(resp);
          } else {
            resolve(resp.data.data.getScoreDistributionValues);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async getTimePerData(datafileId, groupSelector) {
    return new Promise((resolve, reject) => {
      ApiCall({
        method: "post",
        data: {
          query: `
          query getMedianTimePerDatapoint($datafileId: ID!,$groupSelector: String){
            getMedianTimePerDatapoint(datafileId: $datafileId, groupSelector: $groupSelector)

          }
          `,
          variables: {
            datafileId: datafileId,
            groupSelector
          }
        }
      })
        .then((resp) => {
          if (resp?.data?.errors) {
            reject(resp);
          } else {
            resolve(resp.data.data.getMedianTimePerDatapoint);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async getQualityScorePerformanceData(
    datafileId,
    groupSelector,
    cleaned,
    scoreType
  ) {
    return new Promise((resolve, reject) => {
      ApiCall({
        method: "post",
        data: {
          query: `
            query getQualityScorePerformance($datafileId: ID!,$groupSelector: String, $cleaned: Boolean, $scoreType: String){
              getQualityScorePerformance(datafileId: $datafileId,  groupSelector: $groupSelector, cleaned: $cleaned, scoreType:$scoreType)

            }
          `,
          variables: {
            datafileId: datafileId,
            groupSelector,
            cleaned,
            scoreType
          }
        }
      })
        .then((resp) => {
          if (resp?.data?.errors) {
            reject(resp);
          } else {
            resolve(resp.data.data.getQualityScorePerformance);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async getISPositiveNegative(datafileId, dataPointIdentifier, cleaned) {
    let posititiveNegativeData = null;
    dataPointIdentifier =
      dataPointIdentifier && dataPointIdentifier !== "All"
        ? dataPointIdentifier
        : null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getISPositiveNegative($datafileId: ID!, $dataPointIdentifier: String,$cleaned: Boolean){
            getISPositiveNegative(datafileId: $datafileId, dataPointIdentifier: $dataPointIdentifier, cleaned: $cleaned)
            {
              Positive
              Negative
            }
          }
        `,
        variables: {
          datafileId,
          dataPointIdentifier,
          cleaned
        }
      }
    });

    if (result?.data?.data?.getISPositiveNegative && !result?.data?.errors) {
      posititiveNegativeData = result.data.data.getISPositiveNegative;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }

    return posititiveNegativeData;
  }

  async getAvgPredictionData(datafileId) {
    let avgPredtionChartData = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getAveragePredictionsChart($datafileId: ID!) {
            getAveragePredictionsChart(datafileId: $datafileId) {
              datapointIdentifier
              data {
                sameAnswer
                differentAnswer
              }
            }
          }
        `,
        variables: {
          datafileId
        }
      }
    });

    if (
      result?.data?.data?.getAveragePredictionsChart &&
      !result?.data?.errors
    ) {
      avgPredtionChartData = result.data.data.getAveragePredictionsChart;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }

    return avgPredtionChartData;
  }

  async getAllDatarecords(
    datafileId,
    dataPointIdentifier,
    scoreType,
    groupSelector,
    allDataRecords
  ) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDatarecords($datafileId: ID!, $dataPointIdentifier: String, $scoreType: String, $groupSelector: String, $allDataRecords: Boolean){
            getDatarecords(datafileId: $datafileId, dataPointIdentifier: $dataPointIdentifier, scoreType: $scoreType, groupSelector: $groupSelector,allDataRecords: $allDataRecords){
              numberOfRecords
              numberOfIncludedRecords
              includeTimestamp
              includeIBS
              includeCS
              includePS
              includeOES
              allDatarecords {
                recordId
                oesAnswer
                oesAnswerCategory
                oesFeedback
                oesComment
                oesSwearword
                trafficLight
                rank
                qualityScore
                group
                weight
                validDatapoints
                oesAnswerDetectedLanguage
              }
            }
          }
        `,
        variables: {
          datafileId,
          dataPointIdentifier,
          scoreType,
          groupSelector,
          allDataRecords
        }
      }
    });

    if (result?.data?.data?.getDatarecords && !result?.data?.errors) {
      return result.data.data.getDatarecords;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  async getDataRecordDetails(datafileId, dataRecordId) {
    let dataRecordDetail = null;

    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDatarecordDetails($datafileId: ID!, $recordId: String) {
            getDatarecordDetails(datafileId: $datafileId, recordId: $recordId) {
              ...on DatarecordOverviewDetails {
                rank
                rankPercentile
                datafileRScore
                totalDataPoints
                validDataPoints
                averageStandardDeviation
                standardDeviation
                rWeight
                informationScore
                predictionScore
                socialDesirabilityScore
                timeScore
                CScore
                OEScore
                itemBatteryScore
                isIncluded
                excludedReason
                respondentWeights {
                  IS
                  PS
                  SDS
                  TS
                  IBS
                  CS
                  OES
                }
              }

              ...on DatarecordTimescoreDetails {
                timeScore
                rankPercentile
                totalDataPoints
                validDataPoints
                group
                datapoints {
                  timescore
                  medianTimestamp
                  respondentTimestamp
                }
              }

              ...on DatarecordItemBatteryDetails {
                rankPercentile
                itemBatteryScore
                validDataPoints
                totalDataPoints
                datapoints {
                 itemBatteryScore
                 dataPointIdentifier
                 itemBatteryAnswers
                 matrixPattern
                 matrixFeedback
                 enablePatternCheck
                }
              }

              ...on DatarecordCScoreDetails {
                CScore
                rankPercentile
                validDataPoints
                totalDataPoints
                datapoints {
                  CScore
                  datapointIdentifier
                  averagePredictions {
                    sameAnswer
                    differentAnswer
                  }
                  respondentChosenAnswer
                  respondentPredictionForChosenAnswer
                }
              }

              ...on DatarecordPredictionScoreDetails {
                  isPredictionScore
                  predictionScore
                  rankPercentile
                  validDataPoints
                  totalDataPoints
                  datapoints {
                    predictionScore
                    datapointIdentifier
                    prediction {
                      name
                      predictedValue
                      empiricalValue
                    }
                  }
              }

              ...on DatarecordOEScoreDetails {
                oeScore
                rankPercentile
                validDataPoints
                totalDataPoints
                enableDuplicateChecks
                isEnableWrongTopicCheck
                datapoints {
                  oeScore
                  datapointIdentifier
                  questionTitle
                  keywords
                  answer
                  answerCategory
                  answerDetectedLanguage
                  duplicateReasons
                }
              }
            }
          }
        `,
        variables: {
          datafileId,
          recordId: dataRecordId
        }
      }
    });

    if (result?.data?.data?.getDatarecordDetails && !result?.data?.errors) {
      dataRecordDetail = result.data.data.getDatarecordDetails;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }

    return dataRecordDetail;
  }

  // Question page endpoints
  async getDataPointResult(datafileId, dataPointIdentifier) {
    let dataPointResult = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDataPointResult($datafileId: ID!, $dataPointIdentifier: String!){
            getDataPointResult(datafileId: $datafileId, dataPointIdentifier: $dataPointIdentifier){
                title
                uncleanedResult
                cleanedResult
            }
          }
        `,
        variables: {
          datafileId: datafileId,
          dataPointIdentifier: dataPointIdentifier
        }
      }
    });

    if (result?.data?.data?.getDataPointResult && !result?.data?.errors) {
      dataPointResult = result.data.data.getDataPointResult;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
    return dataPointResult;
  }

  async getDataPointPrediction(datafileId, dataPointIdentifier) {
    let dataPointResult = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDataPointPrediction($datafileId: ID!, $dataPointIdentifier: String!){
            getDataPointPrediction(datafileId: $datafileId, dataPointIdentifier: $dataPointIdentifier){
                title
                averagePredictionsResult
            }
          }
        `,
        variables: {
          datafileId: datafileId,
          dataPointIdentifier: dataPointIdentifier
        }
      }
    });

    if (result?.data?.data?.getDataPointPrediction && !result?.data?.errors) {
      dataPointResult = result.data.data.getDataPointPrediction;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
    return dataPointResult;
  }

  async getDataPointMetadata(datafileId, dataPointIdentifier) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDataPointMetadata($datafileId: ID!, $dataPointIdentifier: String!) {
            getDataPointMetadata(datafileId: $datafileId, dataPointIdentifier: $dataPointIdentifier) {
              dataPointIdentifier
              dataPointType
              questionTitle
              algorithms {
                IS
                PS
                SDS
                TS
                IBS
                CS
              }
            }
          }
        `,
        variables: {
          datafileId: datafileId,
          dataPointIdentifier: dataPointIdentifier
        }
      }
    });

    if (result?.data?.data?.getDataPointMetadata && !result?.data?.errors) {
      return result.data.data.getDataPointMetadata;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /********* Delete Datafile **********/
  async deleteDatafile(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation deleteDatafile($datafileId: ID!) {
            deleteDatafile(datafileId: $datafileId) {
              status
              message
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (result?.data?.data?.deleteDatafile && !result?.data?.errors) {
      return result.data.data.deleteDatafile;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  /********* Reset Datafile **********/
  async reuploadDatafile(datafileId, overwriteScores) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
            mutation reuploadDatafile($datafileId: ID!, $overwriteScores: Boolean!) {
              reuploadDatafile(datafileId: $datafileId, overwriteScores: $overwriteScores) {
                status
                message
              }
            }
          `,
        variables: {
          datafileId: datafileId,
          overwriteScores
        }
      }
    });

    if (result?.data?.data?.reuploadDatafile && !result?.data?.errors) {
      return result.data.data.reuploadDatafile;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  /********* Transfer Datafile Ownership to Redem **********/
  async transferDatafileOwnershipToRedem(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation transferDatafileOwnershipToRedem($datafileId: ID!) {
            transferDatafileOwnershipToRedem(datafileId: $datafileId) {
              status
              message
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (
      result?.data?.data?.transferDatafileOwnershipToRedem &&
      !result?.data?.errors
    ) {
      return result.data.data.transferDatafileOwnershipToRedem.status;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  async getTimeScoreMetadata(datafileId, cleaned, groupSelector) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getTimescoreMetadata($datafileId: ID!, $cleaned: Boolean, $groupSelector: String) {
            getTimescoreMetadata(datafileId: $datafileId, cleaned: $cleaned, groupSelector: $groupSelector) {
              numberOfGroupSelectors
              numberOfRecords
              numberOfValidRecords
              numberOfInvalidRecords
              numberOfDatapoints
            }
          }
        `,
        variables: {
          datafileId,
          cleaned,
          groupSelector
        }
      }
    });

    if (result?.data?.data?.getTimescoreMetadata && !result?.data?.errors) {
      return result.data.data.getTimescoreMetadata;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  async getIBSMetadata(datafileId, cleaned) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getIBSMetadata($datafileId: ID!, $cleaned: Boolean) {
            getIBSMetadata(datafileId: $datafileId, cleaned: $cleaned) {
              numberOfItemBatteries
              numberOfRecords
              numberOfValidRecords
              numberOfInvalidRecords
              numberOfItems
            }
          }
        `,
        variables: {
          datafileId,
          cleaned
        }
      }
    });

    if (result?.data?.data?.getIBSMetadata && !result?.data?.errors) {
      return result.data.data.getIBSMetadata;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  async getOESMetadata(datafileId, cleaned) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getOESMetadata($datafileId: ID!, $cleaned: Boolean) {
            getOESMetadata(datafileId: $datafileId, cleaned: $cleaned) {
              numberOfRecords
              numberOfDatapoints
              numberOfValidRecords
              numberOfInvalidRecords
              isEnableWrongTopicCheck
              enableDuplicateChecks
            }
          }
        `,
        variables: {
          datafileId,
          cleaned
        }
      }
    });

    if (result?.data?.data?.getOESMetadata && !result?.data?.errors) {
      return result.data.data.getOESMetadata;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  async getCertaintyScoreMetadata(datafileId, cleaned) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCertaintyScoreMetadata($datafileId: ID!, $cleaned: Boolean) {
            getCertaintyScoreMetadata(datafileId: $datafileId, cleaned: $cleaned) {
              numberOfRecords
              numberOfDatapoints
              numberOfValidRecords
              numberOfInvalidRecords
            }
          }
        `,
        variables: {
          datafileId,
          cleaned
        }
      }
    });

    if (
      result?.data?.data?.getCertaintyScoreMetadata &&
      !result?.data?.errors
    ) {
      return result.data.data.getCertaintyScoreMetadata;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  async getSDSMetadata(datafileId, cleaned) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getSDSMetadata($datafileId: ID!, $cleaned: Boolean) {
            getSDSMetadata(datafileId: $datafileId, cleaned: $cleaned) {
              numberOfRecords
              numberOfDatapoints
              numberOfValidRecords
              numberOfInvalidRecords
            }
          }
        `,
        variables: {
          datafileId,
          cleaned
        }
      }
    });

    if (result?.data?.data?.getSDSMetadata && !result?.data?.errors) {
      return result.data.data.getSDSMetadata;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  async getPredictionScoreMetadata(datafileId, cleaned) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getPredictionScoreMetadata($datafileId: ID!, $cleaned: Boolean) {
            getPredictionScoreMetadata(datafileId: $datafileId, cleaned: $cleaned) {
              numberOfRecords
              numberOfDatapoints
              numberOfValidRecords
              numberOfInvalidRecords
            }
          }
        `,
        variables: {
          datafileId,
          cleaned
        }
      }
    });

    if (
      result?.data?.data?.getPredictionScoreMetadata &&
      !result?.data?.errors
    ) {
      return result.data.data.getPredictionScoreMetadata;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  async getGroupSelectors(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
        query GetGroupSelectors($datafileId: ID!) {
          getGroupSelectors(datafileId: $datafileId)
        }`,
        variables: {
          datafileId
        }
      }
    });
    if (result?.data?.data?.getGroupSelectors && !result?.data?.errors) {
      return result.data.data.getGroupSelectors;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  async getValuesForISDataPoints(datafileId, cleaned) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getValuesForISDatapoints($datafileId: ID!, $cleaned: Boolean) {
            getValuesForISDatapoints(datafileId: $datafileId, cleaned: $cleaned) {
              dataPointIdentifier
              scores {
                Positive
                Negative
              }
              chartData {
                name
                data
              }
            }
          }
        `,
        variables: {
          datafileId,
          cleaned
        }
      }
    });

    if (result?.data?.data?.getValuesForISDatapoints && !result?.data?.errors) {
      return result.data.data.getValuesForISDatapoints;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  async getRscorePieChartData(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getRScorePieChart($datafileId: ID!) {
            getRScorePieChart(datafileId: $datafileId) {
              PS
              CS
              TS
              IBS
              OES
            }
          }
        `,
        variables: {
          datafileId
        }
      }
    });

    if (result?.data?.data?.getRScorePieChart && !result?.data?.errors) {
      return result.data.data.getRScorePieChart;
    } else {
      throw new CustomErrorMessage(
        result?.data?.errors[0]?.extensions?.code,
        result?.data?.errors[0].message
      )      
    }
  }

  async getOESPieChartData(datafileId, isCleaned) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getOESPieChart($datafileId: ID!, $isCleaned: Boolean!) {
            getOESPieChart(datafileId: $datafileId, isCleaned: $isCleaned) {
              categories
              numOfTotalAnswers
            }
          }
        `,
        variables: {
          datafileId,
          isCleaned
        }
      }
    });

    if (result?.data?.data?.getOESPieChart && !result?.data?.errors) {
      return result.data.data.getOESPieChart;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /******* Suggest Score for OES ******/
  async sendOESFeedback(
    datafileId,
    recordId,
    dataPointIdentifier,
    suggestedScore,
    comment,
    addDictionaryWords,
    dictionary
  ) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
        mutation sendOESFeedback($datafileId: ID!, $recordId: String!, $dataPointIdentifier: String!, $suggestedScore: Float, $comment: String, $addDictionaryWords: [String], $dictionary: String) {
          sendOESFeedback(datafileId: $datafileId, recordId:$recordId, dataPointIdentifier: $dataPointIdentifier, suggestedScore:$suggestedScore, comment: $comment, addDictionaryWords: $addDictionaryWords, dictionary: $dictionary) {
            status
            message
          }
        }
        `,
        variables: {
          datafileId,
          recordId,
          dataPointIdentifier,
          suggestedScore,
          comment,
          addDictionaryWords,
          dictionary
        }
      }
    });

    if (result?.data?.data?.sendOESFeedback && !result?.data?.errors) {
      return result.data.data.sendOESFeedback.status;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  // Send GQS Feedack
  async sendGQSFeedback(datafileId, recordId, datapointId, feedbackType, pattern) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
        mutation sendGQSFeedback($datafileId: ID!, $recordId: String!, $datapointId: String!, $feedbackType: String!, $pattern: String) {
          sendGQSFeedback(datafileId: $datafileId, recordId:$recordId, datapointId: $datapointId, feedbackType: $feedbackType, pattern: $pattern) {
            status
            message
          }
        }
        `,
        variables: {
          datafileId,
          recordId,
          datapointId,
          feedbackType,
          pattern
        }
      }
    });

    if (result?.data?.data?.sendGQSFeedback && !result?.data?.errors) {
      return result.data.data.sendGQSFeedback.status;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  // Get GQS Data Point Information
  async getGQSDataPointsInfo(datafileId) {
    let datafile = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getGQSDataPointsInfo($datafileId: ID!){
            getGQSDataPointsInfo(datafileId: $datafileId) {
              dataPointIdentifier
              enablePatternCheck
              numberOfItems
              startColumn
              endColumn
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (result?.data?.data?.getGQSDataPointsInfo && !result?.data?.errors) {
      datafile = result.data.data.getGQSDataPointsInfo;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }

    return datafile;
  }

  // Get Traffic Light Data
  async getTrafficLightData(datafileId, scoreType, dataPointIdentifier, cleaned, groupSelector, benchmark = false) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getTrafficLightData($datafileId: ID!, $dataPointIdentifier: String, $scoreType: String, $cleaned: Boolean, $groupSelector: String, $benchmark: Boolean) {
            getTrafficLightData (datafileId: $datafileId, dataPointIdentifier: $dataPointIdentifier, scoreType: $scoreType, cleaned: $cleaned, groupSelector: $groupSelector, benchmark: $benchmark) {
              trafficLightValues {
                Green
                Yellow
                Red
                Grey
                LightGreen
                Average
              }
              benchmarkTrafficLightValues {
                Green
                Yellow
                Red
                Grey
                LightGreen
                Average
              }
            }
          }
        `,
        variables: {
          datafileId,
          dataPointIdentifier,
          scoreType,
          cleaned,
          groupSelector,
          benchmark
        }
      }
    });

    if(response?.data?.data?.getTrafficLightData && !response?.data?.errors) {
      return response?.data?.data?.getTrafficLightData
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      )
    }
  }

  // Get Distribution Data
  async getDistributionData(datafileId, scoreType, dataPointIdentifier, cleaned, groupSelector, benchmark) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getScoreDistributionValues($datafileId: ID!, $dataPointIdentifier: String, $scoreType: String, $cleaned: Boolean, $groupSelector: String, $benchmark: Boolean){
            getScoreDistributionValues(datafileId: $datafileId, dataPointIdentifier: $dataPointIdentifier, scoreType: $scoreType, cleaned: $cleaned, groupSelector: $groupSelector, benchmark: $benchmark) {
              scoreDistribution
              benchmarkScoreDistribution
            }
          }
        `,
        variables: {
          datafileId,
          dataPointIdentifier,
          scoreType,
          cleaned,
          groupSelector,
          benchmark
        }
      }
    });


    if(response?.data?.data?.getScoreDistributionValues && !response?.data?.errors) {
      return response?.data?.data?.getScoreDistributionValues
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      )
    }
  }

  // Get Average Quality Score Data
  async getAverageQualityScoreData(datafileId, scoreType, dataPointIdentifier, cleaned, groupSelector, benchmark) {
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getAverageScore($datafileId: ID!, $dataPointIdentifier: String, $scoreType: String!, $cleaned: Boolean, $groupSelector: String, $benchmark: Boolean) {
            getAverageScore(datafileId: $datafileId, dataPointIdentifier: $dataPointIdentifier, scoreType: $scoreType, cleaned: $cleaned, groupSelector: $groupSelector, benchmark: $benchmark) {
              average
              benchmarkAverage
            }
          }
        `,
        variables: {
          datafileId,
          dataPointIdentifier,
          scoreType,
          cleaned,
          groupSelector,
          benchmark
        }
      }
    });

    if(response?.data?.data?.getAverageScore && !response?.data?.errors) {
      return response?.data?.data?.getAverageScore
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      )
    }
  }
}

export default new DatafileResultService();
